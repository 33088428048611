input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.recharts-text{
  &.recharts-cartesian-axis-tick-value{
    font-size: 12px;
  }
}

.daterangepicker {
  &.show-calendar {
    .drp-buttons {
      @extend .display-flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.auto-apply {
    .drp-buttons {
      display: none;
    }
  }

  .drp-buttons .btn {
    min-height: unset;
  }

  td {
    &.active {
      background: $primary;

      &:hover {
        background: $primary;
      }
    }
  }

  .ranges {
    li{
      &.active, &:hover{
        background-color: $primary;
        color: white;
      }
    }
  }
}

table{
  td{
    color: $text-color;
  }
}

.table-pagination-custom{
  @extend .display-flex;
  justify-content: flex-end;
  padding: 15px;
  padding-bottom: 30px;
  .table-page-list{
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
  }
}

#table-custom-wrapper{
  border-top-left-radius: $border-radius-paper;
  border-top-right-radius: $border-radius-paper;
  border-collapse: collapse;
  overflow: hidden;
  .table-custom{
    thead{
      background-color: $table-header-bg;
      color: $primary;
      tr{
        th{
          border-bottom-color: $border-color;
        }
      }
    }
    tr{
      vertical-align: middle;
    }
    thead th{
      text-transform: uppercase;
      padding: 16px 12px;
      &:first-child{
        padding-left: 20px;
        width: 5.5vw;
      }
      &:last-child{
        padding-right: 20px;
      }
    }
    &.width-unset{
      thead th{
        &:first-child{
          width: unset;
        }
      }
    }
    td{
      padding: 16px 12px;
      &:first-child{
        padding-left: 20px;
      }
      &:last-child{
        padding-right: 20px;
      }
    }
  }
}

.react-bootstrap-table {
  .table {

    .table-header {
      color: $border-color;
    }

    .table-header-custom{
      color: $primary;
      background-color: $table-header-bg;
      text-transform: uppercase;
      th{
        padding-top: 20px;
        padding-bottom: 20px;
        &:nth-child(2){
          width: 21vw;
        }
        &:first-child{
          padding-left: 20px;
        }
        &:last-child{
          padding-right: 20px;
        }
      }
    }

    .table-btn-wrapper {
      @extend .display-flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        margin-right: 1.5rem;
        opacity: 0;
        transition: all .5s;
      }

      .delete-icon {
        opacity: 0;
        transition: all .5s;
      }

      .fa-trash{
        margin-right: 0.6rem;
        cursor: pointer;
      }
    }

    tbody{
      tr:hover{
        background-color: $table-hover-bg;
        .table-btn-wrapper{
          .btn{
            opacity: 1;
          }
        }
        .delete-icon{
          opacity: 1;
        }
      }
    }

    .dropup {
      .caret {
        content: '';
        border-top: 0;
        border-bottom: 4px dashed;
      }
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.react-bootstrap-table-pagination {
  padding: 10px 20px;
  .dropdown {
    display: inline-flex;

    .btn {
      min-height: unset;
      margin-right: 10px;
      background: transparent;
      color: $text-color;
      border: 1px solid $border-color;
    }

    .dropdown-menu {
      background: white;

      .dropdown-item {
        padding: 0px;

        a {
          display: block;
          padding: 0.25rem 1rem;
          background: white;
          @include transition(all, 0.5s, ease);

          &:hover {
            background: $gray-200;
          }
        }
      }
    }
  }

  .pagination {
    justify-content: flex-end;

    .page-item {
      &.active {
        .page-link {
          background: $primary;
          color: white;
        }
      }

      .page-link {
        color: $primary;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.tox-tinymce-aux {
  z-index: 1040 !important;
}
