/* EXTENDABLE */
.display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
}

.default-list-style {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.default-background-style {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
/* EXTENDABLE */
