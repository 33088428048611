.modal-image-wrapper {
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
  padding-right: 15px;
}

.modal-delete-wrapper{
  .modal-delete-header{
    text-align: center;
    position: relative;
    padding: 1rem 0;
    .modal-delete-header-title{
      font-size: 24px;
      font-weight: 600;
    }
    .close-icon-wrapper{
      position: absolute;
      background-color: $border-color;
      padding: 2px 8px;
      border-radius: 50%;
      color: white;
      right: 0;
      top: -4px;
      cursor: pointer;
    }
  }
  .modal-delete-body{
    text-align: center;
    padding-bottom: 1rem;
  }
  .modal-delete-footer{
    text-align: center;
    padding: 1rem 0;
    @extend .display-flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-image-body{
  .modal-dialog{
    max-width: 500px;
  }
  .modal-body{
    padding: 0;
    .close-icon-wrapper{
      &:hover{
        cursor: pointer;
      }
      position: absolute;
      right: -20px;
      top: -20px;
      border-radius: 50%;
      background-color: white;
      padding: 6px 10px 4px 10px;
      .close-icon{
        font-size: 18px;
        color: $text-color;
      }
    }
  }
}

.modal-product-detail{
  .modal-content{
    border-radius: $border-radius-paper;
    padding: 40px 40px 30px 40px;
    .title{
      font-weight: 300;
      font-size: 16px;
    }
    .subtitle{
      font-size: 18px;
    }
  }
  .close-btn{
    position: absolute;
    background-color: $border-color;
    padding: 0.625px 5px;
    border-radius: 50%;
    color: white;
    right: 6%;
    top: 6%;
    cursor: pointer;
    svg{
      transform: rotate(45deg);
    }
  }
}

.modal-upload-wrapper {
  min-height: 60vh;

  .card-files-section {
    max-height: 32vh;
    min-height: 32vh;
    overflow-y: scroll;
    padding-right: 15px;
  }

  .btn-action-wrapper {
    margin-bottom: 15px;
  }
}

.modal-aciton-wrapper {
  text-align: center;

  p {
    margin-bottom: 0px;
  }

  .title {
    font-weight: 700;
  }
}

.modal-dialog{
  max-width: 35vw;
  @include media-breakpoint-down(sm) {
    max-width: unset;
  }
  .modal-header, .modal-footer{
    border: none;
  }
  .modal-title{
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 5vh;
  }
  .modal-header .close{
    margin-top: -60px;
    font-size: 30px;
    @include media-breakpoint-down(sm) {
      margin-top: -10vh;
    }
  }
  .modal-footer{
    justify-content: center;
    padding-bottom: 1.5rem;
  }
  .modal-body{
    @extend .display-flex;
    justify-content: center;
    align-items: center;
    .modal-body-action{
      @extend .display-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #6E6E72;
      margin-top: 15px;
      margin-bottom: 15px;
      .action-icon{
        font-size: 30px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .action-title{
        font-size: 12px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

