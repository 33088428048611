$main-bg: #F8F8F8;
$secondary-bg: #FFFFFF;

$text-color: #6E6E72;
$title-text: #606060;
$title-color: #1A1818;

$nav-color-active: #eaeaf1;
$text-chart-color: #f4901c;

$border-radius-paper: 10px;

$primary: #2B2C6D;
$secondary: #20204f;

$border-color: #bababa;
$table-header-bg: #dfdfe9;
$table-hover-bg: #eaeaf1;

$un-read: #e6f3ff;

$box-shadow: 0 0 10px -4px rgba(black, 0.3);

// btn status
$failed-status-bg: #F2D9D9;
$failed-status-color: #AA0000;

$success-status-bg: #D9E7D9;
$success-status-color: #045D00;

$processing-status-bg:#Feeedd;
$processing-status-color: #f4901c;
