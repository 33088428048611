#dashboard-section {
  .dashboard-pie-chart{
    margin: auto;
    @include media-breakpoint-down(sm){
      width: 100% !important;
      height: auto !important;
    }
  }
  .card-header {

    .card-header-filter-wrapper {
      @extend .display-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;

      .title-wrapper {

      }

      .filter-item {

      }
    }
  }

  .card-content {
    .box-dashboard-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .box-caption-wrapper {
        font-size: 32px;
        font-weight: 700;
        width: 100%;

        .box-caption {
          font-size: 24px;
        }
      }

      canvas{
        height: 350px !important;
        width: 100% !important;
      }

      .box-icon-wrapper {
        text-align: right;
        font-size: 27.34px;
      }
    }
  }

  //.chart-area{
  //  width: 100%;
  //  height: 300px;
  //}

  .table{
    margin-bottom: 0;
    thead, th{
      border: none;
    }
    th{
      padding: 15px 20px;
    }
    td:first-child, th:first-child{
      padding-left: 20px;
    }
    td:last-child, th:last-child{
      padding-right: 20px;
    }
    tr:last-child{
      border-color: $secondary-bg;
    }
    .table-header{
      background-color: $table-header-bg;
      color: $primary;
    }
  }

  .grid-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: auto;

    &.three-section {
      grid-template-columns: repeat(3, 1fr);
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .card-status-section-wrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @include media-breakpoint-down(sm){
      grid-template-columns: 1fr;
    }

    .card-status-header{
      display: flex;
      justify-content: space-between;
      .card-status-subtitle{
        font-size: 12px;
      }
    }
    .card-text-body{
      font-size: 24px;
      margin-top: 10px;
    }
  }

  .total-section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .sales-order-section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .top-five-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }
}
