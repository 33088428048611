// Form Element
.form-control{
  padding: 0.5rem 0.75rem !important;
  font-size: 14px;
  color: $text-color;
  &:focus{
    color: $text-color;
  }
}

.form-group {
  margin-bottom: 20px;
  position: relative;

  &.error {
    color: $danger;

    .form-control {
      border-color: $danger;
      color: $danger;
    }

    .label-help {
      color: $danger;
    }

    .input-group-text {
      border-color: $danger;
    }

    .form-select-wrapper {
      .select__control {
        border-color: $danger !important;
      }
    }
  }

  .form-label {
    margin-bottom: 5px;
    font-size: 12px;
    color: $border-color;
    &.error{
      color: $danger;
    }
  }

  .label-help {
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: calc(100% + 2px);
  }
}

.th-permission{
  text-transform: uppercase;
  text-align: center;
}

.td-permission{
  text-transform: capitalize;
  &.check{
    text-align: center;
    input{
      width: 15px;
      height: 15px;
    }
  }
}

.form-check{
  @extend .display-flex;
  .form-check-input{
    width: 1.4em;
    height: 1.4em;
    margin-right: .5em;
    border: 2px solid #adb5bd;
    cursor: pointer;
    padding: 8px;
  }
  .form-check-label{
    margin-top: .3em;
    margin-right: 3em;
    letter-spacing: 0.03em;
  }
}

#auth-layout{
  .form-group{
    .form-label{
      color: #bababa;
    }
  }
  .form-control{
    background-color: transparent;
    border-color: $border-color;
    color: $text-color;
    &::placeholder{
      color: $border-color;
    }
  }
  .btn{
    margin-left: auto;
  }
  .form-icon{
    position: absolute;
    right: 10px;
    top: 55%;
    font-size: 20px;
  }
  .form-wrapper{
    a{
      font-size: 12px;
    }
  }
}

#change-password-layout{
  .form-group{
    .form-label{
      color: #bababa;
    }
  }
  .form-control{
    background-color: transparent;
    border-color: $border-color;
    color: $text-color;
    &::placeholder{
      color: $border-color;
    }
  }
  .btn{
    margin-left: auto;
  }
  .form-icon{
    position: absolute;
    right: 10px;
    top: 55%;
    font-size: 20px;
  }
  .form-wrapper{
    a{
      font-size: 12px;
    }
  }
}

.form-control {
  border-color: $gray-500;
  padding: 0.6rem 0.75rem;
  border-radius: 10px;

  &:focus {
    border-color: $gray-500;
    box-shadow: none;
  }

  &.type-small {
    font-size: 14px;
    line-height: 1;
  }
}

.form-select {
  border-color: $gray-500;

  &:focus {
    border-color: $gray-500;
    box-shadow: none;
  }
}

.form-check-input {
  border-color: $gray-500;

  &:checked {
    background: $primary;
    border-color: $primary;
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }
}

.input-group {
  &.prefix-group {
    .input-group-text {
      padding-right: 0px;
    }

    .form-control {
      border-left: none;
    }
  }

  &.suffix-group {
    .input-group-text {
      padding-left: 0px;
    }

    &.has-icon{
      .form-control {
        border-right: none;
      }
    }
  }

  .input-group-text {
    background: transparent;
    border-color: $gray-500;
  }

  .form-control {
    border-color: $gray-500;
  }
}

.form-btn-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    min-width: 100px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.form-select-custom {
  .select__control {
    padding: 5px;
    height: auto;

    &:hover {
      border: 1px solid #EBEBEB;
    }
  }

  .select__indicators {
    display: none;
  }

  .select__multi-value__label {
    color: #ffb507;
    font-family: "Rubik Medium", "Sans Serif";
    font-size: 14px;
  }

  .select__multi-value {
    background-color: white;
    border: 1px solid #ffb507;
    border-radius: 25px;
    padding: 2px;
  }

  .select__multi-value__remove {
    color: #ffb507;
    font-size: 14px;
    svg {
      display: block;
    }

    &:hover {
      color: #ffb507;
      background-color: transparent;
    }
  }
}

.form-select-wrapper {
  //.select__value-container {
  //  padding: 10px;
  //}

  .select__multi-value__remove {
    color: white;
    font-size: 14px;
    svg {
      display: block;
    }

    &:hover {
      color: white;
      background-color: transparent;
    }
  }

  .select__multi-value__label {
    color: white;
    font-size: 14px;
  }

  .select__multi-value {
    background-color:  $secondary;
    border: 1px solid $secondary;
    border-radius: 25px;
    padding: 2px;
  }



  .select__control {
    border-color: $gray-500 !important;
    box-shadow: none;
    flex-wrap: nowrap;
    min-height: 39px;
  }

  .select__single-value {
    color: $text-color;
  }


  .select__menu {
    z-index: 2;

    .select__option {
      color: $text-color;

      &.select__option--is-focused {
        background: $gray-300;
      }

      &.select__option--is-selected {
        color: white;
        background: $primary;
      }

      &.select__option--is-disabled {
        opacity: 0.5;
      }
    }
  }
}

.form-with-button-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;

  .form-input-wrapper {
    flex: 1;
  }
}
// End Form Element
