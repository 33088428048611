body {
  font-family: 'Noto Sans JP', sans-serif;
  background: $main-bg;
  color: $text-color;
  font-size: 14px;
}

::-webkit-scrollbar {
  height: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 5px;
}

a {
  color: $primary;
  text-decoration: none;
  @include transition(all, 0.5s, ease);

  &:hover {
    color: $secondary;
  }
}

#screen-loader {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.height-unset{
    height: unset;
  }

  .loader {
    width: 100px;
  }
}

// Auth Layout
#auth-layout {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .main-wrapper {
    max-width: 500px;
    margin: auto;
    background-color: $secondary-bg;
    border-radius: 10px;
    padding: 30px;
  }

  .copyright{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: $primary;
  }

  .auth-header {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: nowrap;

    .title {
      font-weight: 400;
    }

    .subtitle{
      font-size: 12px;
      margin-top: 16px;
      line-height: 1.5;
    }
  }

  .separator {
    margin: 20px 0px;
  }
}
// End Auth Layout

// App Layout
#app-layout {
  .app-header-separator {
    height: 60px;
  }

  .title {
    color: $title-text;
    font-size: 20px;
    font-weight: 600;
    .not-active{
      font-weight: 300;
    }
  }

  .subtitle{
    color: $title-text;
    font-size: 20px;
    font-weight: 300;
  }

  .badge-notification{
    position: relative;
    cursor: pointer;
    .notification-sign{
      background-color: red;
      @extend .display-flex;
      align-items: center;
      justify-content: center;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: calc(-1px - 0.1vh);
      left: calc(10px - 0.1vw);
    }
  }

  .app-header {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 30px;
    position: fixed;
    width: 100%;
    z-index: 99;
    border-bottom: 1px solid $gray-400;
    background: $secondary-bg;
    box-shadow: 0px -5px 15px -3px rgba(0 ,0 ,0,55%);

    .brand-wrapper {
      @extend .display-flex;
      align-items: center;

      .mobile-menu {
        font-size: 22px;
        margin-right: 15px;
        display: none;
      }

      .brand-icon{
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .navigation-wrapper {
      flex: 1;
      @extend .display-flex;
      align-items: center;
      justify-content: flex-end;

      .navigation-item {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0px;
        }

        .icon {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .dropdown-wrapper {
        position: relative;

        .dropdown-content {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: calc(100% - 10px);
          right: 0px;
          border-radius: 5px;
          min-width: 200px;
          box-shadow: $box-shadow;
          padding: 15px 0px;
          font-size: 12px;
          background: white;
          @include transition(all, 0.5s, ease);

          &.show {
            visibility: visible;
            opacity: 1;
            top: calc(100% + 10px);
          }

          .dropdown-title {
            display: block;
            padding: 0px 15px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 5px;
          }

          .dropdown-list {
            @extend .default-list-style;

            .dropdown-item {
              padding: 0px;

              .dropdown-link {
                @extend .display-flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                color: $text-color;
                padding: 5px 15px;
                background: white;
                @include transition(all, 0.5s, ease);

                &:hover,
                &:focus {
                  background: $gray-200;
                }
              }
            }
          }

          hr {
            margin: 10px 0px;
          }
        }
      }

      .profile-wrapper {
        @extend .display-flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
        background: $gray-500;
        border-radius: 100%;
        color: white;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .app-sidebar {
    width: 18%;
    position: fixed;
    height: calc(100vh - 60px);
    z-index: 98;
    left: 0;
    @include transition(all, 0.5s, ease);

    &.show-mobile {
      left: 0px;
      background-color: $secondary-bg;
    }

    .sidebar-main-wrapper {
      position: relative;
      padding: 20px 0 20px 15px;
      height: 100%;

      .sidebar-copyright{

      }

      .app-sidebar-header {
        padding: 0px 15px;
      }

      .app-sidebar-content {
        overflow-y: auto;
      }

      .app-sidebar-footer {
        position: absolute;
        bottom: 0;
        padding: 0 15px 15px 15px;
        width: 100%;
        font-size: 11px;
        color: $border-color;
      }
    }

    .main-nav-list {
      @extend .default-list-style;
      margin-right: 15px;

      .nav-icon{
        font-size: 16px;
      }

      .nav-item {
        &.opened {
          .nav-link{
            background: $nav-color-active;
            color: $primary;
            border-radius: 10px;
            .icon-wrapper{
              svg{
                path{
                  fill: $primary;
                }
              }
            }
          }
          .nav-dropdown {
            height: 100%;
            margin-bottom: 10px;
          }
        }
      }

      .nav-link {
        @extend .display-flex;
        align-items: center;
        color: $text-color;
        padding: 8px 10px;
        position: relative;

        .nav-text{
          margin-left: 5px;
        }

        &.active {
          background: $nav-color-active;
          color: $primary;
          border-radius: 10px;
          .icon-wrapper{
            svg{
              path{
                fill: $primary;
              }
            }
          }
        }

        .icon-wrapper {
          min-width: 30px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .nav-dropdown {
      height: 0px;
      margin-bottom: 0px;
      overflow: hidden;
    }

    .nav-dropdown-list {
      list-style: none;
      padding-left: 0;

      .nav-dropdown-link {
        @extend .display-flex;
        align-items: center;
        color: $text-color;
        padding: 6px 30px 6px 45px;
        position: relative;

        &.active {
          color: $primary;
        }
      }
    }

    .nav-badge {
      position: absolute;
      right: 12.5px;
      font-size: 9px;
      line-height: 10px;
      min-width: 18px;
      min-height: 13px;
      background: $primary;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .app-content {
    margin-left: 17.5%;
    padding: 25px 0px;
    min-height: calc(100vh - 60px);

    .app-container {
      padding: 0px 50px;
    }
  }

  .app-slide-popup {
    position: fixed;
    z-index: 100;
    top: 0px;
    background: white;
    width: 370px;
    right: -100%;
    height: 100%;
    @include transition(all, 0.5s, ease);

    &.show {
      right: 0px;
    }

    .app-slide-header {
      @extend .display-flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      height: 60px;
      border-bottom: 1px solid $gray-400;

      .input-group {
        padding-right: 30px;

        .input-group-text {
          border: none;
          background: none;
        }

        .form-control {
          border: none;
        }
      }

      .title {
        margin-bottom: 0px;
        font-weight: 700;
      }

      .icon {
        cursor: pointer;
        font-size: 18px;
      }
    }

    .app-slide-content {
      padding: 0px;
      height: calc(100vh - 60px);
      overflow: auto;

      .slide-item {
        padding: 15px;
        border-bottom: 1px solid $gray-400;
        cursor: pointer;
        background: white;
        @include transition(all, 0.5s, ease);

        &.read{
          background-color: $un-read;
        }

        &:hover {
          background: $gray-200;
        }

        &.type-search {
          .title {
            margin-bottom: 10px;
            font-size: 18px;
            padding-left: 5px;
            border-left: 3px solid $primary;
          }

          .subtitle {
            font-size: 14px;
            font-weight: 700;
          }

          .description {
            font-size: 12px;
            @include ellipsis(2);
            margin-bottom: 0px;
          }
        }

        .title {
          margin-bottom: 5px;
          font-size: 16px;
        }

        .summary {
          margin-bottom: 0px;
        }

        .date {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .app-backdrop {
    position: fixed;
    z-index: 99;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.6);
    visibility: hidden;
    opacity: 0;
    @include transition(all, 0.5s, ease);

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(lg) {
    .app-header-separator {
      height: 50px;
    }

    .app-header {
      height: 50px;

      .brand-wrapper {
        .mobile-menu {
          display: block;
        }

        .logo {
          height: 30px;
        }
      }

      .navigation-wrapper {
        .navigation-item {
          .icon {
            font-size: 16px;
          }
        }

        .profile-wrapper {
          width: 30px;
          height: 30px;
        }
      }
    }

    .app-sidebar {
      width: 250px;
      left: -250px;
      z-index: 100;
      top: 0;
      height: calc(100vh);
    }

    .app-content {
      min-height: calc(100vh - 50px);
      margin-left: 0px;

      .app-container {
        padding: 0px 30px;
      }
    }

    .app-slide-popup {
      width: 75%;

      .app-slide-header {
        height: 50px;
      }

      .app-slide-content {
        height: calc(100vh - 50px);
      }
    }
  }

  .react-bootstrap-table table {
    &.table-fixed{
      table-layout: fixed;
      white-space: unset;
    }
    word-break: break-word;
    table-layout: auto;
    white-space: nowrap;
  }

  //@include media-breakpoint-down(md) {
  //  .react-bootstrap-table table {
  //    table-layout: auto !important;
  //  }
  //}
}
// End Layout

.modal-header {
  .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.button-submit-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .title{
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  .label-wrapper {
    width: 100%;
    .title {
      font-size: 16px;
    }
  }

  .button-group-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    .button-group {
      width: 21%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .modal-image-wrapper {
    .card-files-section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-500;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
