$base-range: 0.25rem;

@for $i from 1 through 12 {
  .ml-#{$i}{
    margin-left: $base-range * $i !important;
  }
  .mr-#{$i}{
    margin-right: $base-range * $i !important;
  }
  .mt-#{$i}{
    margin-top: $base-range * $i !important;
  }
  .mb-#{$i}{
    margin-bottom: $base-range * $i !important;
  }
}

// Default Page Layout
.default-page-layout {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 20px;
  grid-auto-rows: auto;

  &.layout-reverse {
    grid-template-columns: 4fr 8fr;
  }

  &.layout-full{
    grid-template-columns: 1fr;
  }

  .layout-content {
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: auto;
  }

  .floating-content {
    position: sticky;
    position: -webkit-sticky;
    top: 80px;
  }

  &.type-profile {
    .card-files-section {
      &.single-file {
        justify-content: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;

    &.layout-reverse {
      grid-template-columns: 1fr;
    }

    &.type-profile {
      display: flex;
      flex-direction: column;
    }
  }
}

.multiple-paper-section {
  .card-paper {
    margin-bottom: 20px;
  }
}

.ml-auto{
  margin-left: auto !important;
}

.pr-12{
  padding-right: 3rem !important;
  @include media-breakpoint-down(sm){
    padding-right: unset !important;
  }
}

.pl-unset{
  padding-left: unset !important;
}

.mb-unset{
  margin-bottom: unset !important;
}

.mr-unset{
  margin-right: unset !important;
}

.toasted-bar{
  position: fixed;
  width: 300px;
  height: fit-content;
  text-align: center;
  padding: 24px;
  transition: all .5s;
  bottom: 20px;
  right: 20px;
  background-color: $secondary-bg;
  border-radius: .3rem;
  opacity: 0;
  transform: translateX(100px);
  .progress-bar{
    background-color: $primary;
  }
  &.show{
    opacity: 1;
    transform: translateX(0);
  }
}

.offcanvas{
  .icon-wrapper{
    padding: 6px 10px 6px 8px;
    display: flex;
    border-radius: 50%;
    border: 2px solid $text-color;
    .icon{
      font-size: 16px;
    }
  }
}

.page-header{
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  .btn-wrapper{
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .btn-primary{
      margin-left: 16px;
    }
  }
  &.type-form{
    justify-content: unset;
    .icon-wrapper{
      padding: 6px 10px 6px 8px;
      display: flex;
      border-radius: 50%;
      border: 2px solid $text-color;
      .icon{
        font-size: 16px;
      }
    }

    .title{
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  padding: 0 10px;
  width: fit-content;
  text-align: center;
  border-radius: 5px;

  &.type-red {
    color: #AA0000;
    background-color: #F2D9D9;
  }

  &.type-green {
    color: #045D00;
    background-color: #D9E7D9;
  }
}

.icon-wrapper{
  &.icon-back{
    cursor: pointer;
  }
}
