// Main Component
.card-paper-shadow {
  background: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
}

.image-hover-cursor{
  &:hover{
    cursor: pointer;
  }
}

.overflow-hidden{
  overflow: hidden;
}

.order-price{
  overflow: hidden;
  max-width: 10vw;
}

.order-icon-delete{
  position: absolute;
  right: 1rem;
}

.table-header{
  text-transform: uppercase;
}

.image-default-size{
  width: 100%;
  height: 100%;
}

.nav-tab-wrapper{
  display: flex;
  flex-wrap: unset;
  margin-bottom: 20px;
  .nav-tab-item{
    background-color: white;
    width: 100%;
    .nav-link{
      border-radius: $border-radius-paper;
      color: $text-color;
      text-align: center;
      &.active {
        color: white;
        background-color: $primary;
      }
    }
  }
}

#stock-product-section{
  .table-responsive{
    border-radius: $border-radius-paper;
    tbody tr td:first-child{
      padding-left: 20px;
    }
    tbody tr td:last-child{
      padding-right: 20px;
    }
  }
  .react-bootstrap-table-pagination{
    padding-left: 20px;
    padding-right: 20px;
  }
  .stock-product-header{
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    grid-gap: 15px;
    margin-bottom: 20px;
    .title{
      margin-bottom: 8px;
      font-weight: 300;
      font-size: 14px;
    }
    .product-name{
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 18px;
    }
    .subtitle{
      font-weight: 400;
      font-size: 12px;
    }
    img{
      width: 100%;
      height: 100%;
      border-radius: $border-radius-paper;
    }
  }
}

.offcanvas{
  &.offcanvas-end{
    width: 600px;
  }
  .offcanvas-header{
    padding: 25px;
  }
  #stock-canvas-wrapper{
    .table{
      thead{
        th{
          &:first-child{
            padding-left: 20px;
            width: 9vw;
          }
          &:nth-child(2){
            width: 19vw;
          }
          &:last-child{
            width: 7vw;
            padding-right: 20px;
            text-align: right;
          }
        }
      }
      tbody{
        td{
          &:first-child{
            padding-left: 20px;
          }
          &:last-child{
            padding-right: 20px;
            text-align: right;
          }
        }
      }
    }
    .stock-canvas-body{
      img{
        width: 90px;
        height: 90px;
        border-radius: $border-radius-paper;
        border: 1px solid $border-color;
        margin-right: 10px;
      }
      .title{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 2px;
      }
      .product-name{
        font-size: 16px;
        font-weight: 700;
      }
      .subtitle{
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 2px;
      }
      padding-left: 25px;
      padding-right: 25px;
      .stock-canvas-available{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .stock-canvas-available-title{
          margin-top: 10px;
          font-size: 16px;
          color: $primary;
          font-weight: 700;
          &:last-child{
            margin-left: 20px;
          }
        }
      }
      .stock-canvas-items-wrapper{
        @extend .display-flex;
        align-items: center;
        justify-content: flex-end;
        .stock-canvas-items{
          text-align: right;
          &:last-child{
            margin-left: 30px;
          }
        }
        .stock-canvas-title{
          font-weight: 300;
        }
      }
    }
  }
}

.card-text-wrapper{
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  .card-text{
    font-weight: 700;
    font-size: 14px;
    margin-bottom: unset;
  }
  .card-number{
    font-size: 24px;
    font-weight: 700;
  }
}

.card-detail-stock{
  //@extend .display-flex;
  //align-items: center;
  //justify-content: space-between;
  min-height: 45px;
  .card-stock-number{
    margin-top: 0.5rem;
    word-break: break-word;
    font-size: 1rem;
    font-weight: bold;
  }
  h3{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.table-responsive{
  .table-status-custom{
    width: 12vw;
  }
  thead{
    tr{
      th{
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  tbody{
    tr{
      td{
        color: $text-color;
        vertical-align: middle;
        word-break: break-word;
        padding: 16px 20px;
      }
    }
  }
}

.table-product-image{
  width: 43px;
  height: 43px;
  border: 1px solid $border-color;
  border-radius: 10px;
  object-fit: cover;
  &.for-brand{
    border: unset;
    border-radius: 50%;
  }
}

.table-input-wrapper{
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: grid;
  grid-template-columns: .5fr 1fr 1.2fr 1.3fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  &.header{
    background-color: $border-color;
  }
  .table-input-header{
    color: $primary;
    text-transform: uppercase;
    font-weight: 700;
  }
  //.table-input{
  //
  //}
}

.text-bold{
  font-weight: 600;
}

.text-warning{
  color: $text-chart-color !important;
}

.text-primary{
  color: $primary !important;
}

.text-minus-stock{
  color: $failed-status-color;
  font-weight: 700;
}

.text-plus-stock{
  color: $success-status-color;
  font-weight: 700;
}

#report-section{
  .card-header {
    .card-header-filter-wrapper {
      @extend .display-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;
    }
  }
}

.table-error-icon{
  font-size: 16px;
  cursor: pointer;
}

.modal-list-error{
  height: 50vh;
  overflow-y: scroll;
}

.card-paper {
  background: white;
  border-radius: $border-radius-paper;

  .card-header {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    background: $table-header-bg;
    border-top-left-radius: $border-radius-paper;
    border-top-right-radius: $border-radius-paper;
    padding: 15px 20px;
    border-bottom: unset;
    min-height: 40px;

    .card-header-title {
      margin-bottom: 0px;
      color: $primary;
      font-weight: 700;
    }
  }

  .card-content {
    padding: 20px;

    &.has-table{
      padding-bottom: 0;
    }

    &.not-full{
      padding-bottom: 5px;
    }

    &.type-button {
      padding: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    .card-content {
      padding: 15px;
    }
  }
}

.card-empty-content {
  text-align: center;
  font-weight: 700;
  color: $danger;
}

.input-group-text{
  border-radius: 10px;
}

.send-order-option-wrapper{
  opacity: 0;
  position: absolute;
  z-index: -5;
  transition: all .5s;
  transform: translateY(-20px);
  top: 40px;
  left: 8%;
  width: 100%;
  &.open{
    opacity: 1;
    transform: translateY(0);
    z-index: 10;
  }
  .card-paper{
    box-shadow: $box-shadow;
    .card-option-item{
      display: block;
      padding: 8px 12px;
      color: $text-color;
      &:first-child{
        border-bottom: 2px solid $border-color;
      }
    }
  }
}

.image-wrap{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.card-icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  &.white{
    background-color: $secondary-bg;
    fill: $primary;
  }
  img, svg{
    width: 16px;
    height: 16px;
  }
  svg path{
    fill: $secondary-bg;
  }
}

.default-filter-section {
  @extend .display-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;

  &.align-items-unset{
   align-items: unset;
  }

  &.type-mb-unset{
    margin-bottom: unset;
  }

  .filter-item {
    margin-right: 20px;
    margin-bottom: 15px;
    flex: 20% 0;
    .input-group{
      .form-control{
        padding: 0.375rem 0.75rem;
      }
    }
    &.type-button{
      flex: 5% 0;
      align-self: flex-end;
    }
    &.type-button-full{
      flex: 15% 0;
      align-self: flex-end;
    }
    &.type-search-full{
      margin-right: 0;
      flex: 100% 0;
    }
    &.type-half-full{
      flex: 50% 0;
    }
    &.type-date{
      flex: 25% 0;
    }
    &.type-input-select{
      flex: 35% 0;
    }
    &.filter-special {
      flex: 30% 0;

      &.type-search {
        flex: 40% 0;
      }
    }
    @include media-breakpoint-down(md) {
      flex: 100%;
      margin-right: unset;
      &.type-date{
        flex: 100%;
      }
      &.filter-special {
        flex: 100%;

        &.type-search {
          flex: 100%;
        }
      }
    }
  }
}

.vui-progress-label-component {
  margin-bottom: 15px;
  font-size: 12px;

  .title {
    margin-bottom: 5px;
  }

  .vui-progress-bar {
    height: 10px;
    margin-bottom: 5px;

    .progress-bar {
      background: $primary;
    }
  }
}

.nav-tabs .nav-link {
  margin-bottom: unset;
  background: none;
  border: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.nav-tabs .nav-link.active{
  border-color: unset;
  border: none;
  color: unset;
  background-color: unset;
  border-bottom: 2px solid $primary;
  font-weight: bold;
}

.page-header-component {
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm){
    flex-direction: column;
    align-items: flex-start;

    .title{
      margin-bottom: 10px;
    }

    .btn-wrapper{
      width: 100%;
      .btn-primary{
        width: 100%;
      }
    }
  }

  .action-wrapper {
    @extend .display-flex;
    align-items: center;

    .action-item {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.text-download-link{
  color: #0a53be;
  font-size: 10px;
  align-items: unset;
  justify-content: unset;
  padding: 0;
  min-height: unset;
}

.icon-delete-field{
  font-size: 20px;
}

.card-files-section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;

  &:focus {
    outline: none;
  }

  &.single-file {
    grid-template-columns: minmax(0, 200px);

    &.full-template{
      grid-template-columns: 1fr;
    }

    .card-file {
      .card-inner {
        .card-filled-wrapper {
          .action-wrapper {
            min-height: 40px;
          }

          .icon-delete-file {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.select-media {
    grid-template-columns: repeat(4, 1fr);
  }

  &.type-center {
    justify-content: center;
  }

  .card-file {
    border: 1px dashed $gray-500;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);
    border-radius: $border-radius-paper;

    &:hover {
      background: $gray-200;
    }

    &.filled {
      border: none;
      background: $gray-200;
    }

    &.select {
      border: 3px solid transparent;

      &:hover {
        border-color: $primary;
      }
    }

    .card-inner {
      @extend .display-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &:focus {
        outline: none;
      }

      .card-label {
        text-align: center;
        margin-bottom: 0px;
      }

      .card-filled-wrapper {
        width: 100%;
        height: 100%;
        @extend .display-flex;
        overflow: hidden;
        position: relative;
        align-items: center;
        border-radius: $border-radius;
        justify-content: center;

        .filled-icon {
          font-size: 32px;
        }

        .image {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
        }

        .info-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background: rgba($primary, 0.5);
          padding: 15px;
          opacity: 0;
          @include transition(all, 0.5s, ease);

          &:hover {
            opacity: 1;
          }

          .info-size {
            font-size: 8px;
            margin-bottom: 5px;
          }

          .info-name {
            text-align: center;
            width: 100%;
            @include ellipsis(1);
          }
        }

        .action-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          min-height: 30px;

          .icon-delete-file {
            color: $danger;
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
          }

          .action-check {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5%;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.card-upload-wrapper {
  .upload-dropzone {
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    margin-bottom: 15px;
    min-height: 200px;
    border: 1px dashed $gray-500;
    border-radius: $border-radius;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);

    &:hover {
      background: $gray-200;
    }

    .icon {
      font-size: 86px;
      margin-right: 15px;
    }

    .title {
      font-weight: 700;
      margin-bottom: 0px;
    }

    .summary {
      margin-bottom: 0px;
    }
  }
}

.button-submit-print-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: auto;
  align-items: center;

  .title {
    font-size: 16px;
  }

  .button-wrapper {
    display: grid;
    grid-template-columns: 115px 115px 115px;
    grid-gap: 20px;
    grid-auto-rows: auto;
    align-items: center;

    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

#setting-section{
  .card-setting-title{
    background-color: $table-header-bg;
    color: $primary;
    text-transform: capitalize;
    padding: 15px;
    font-weight: 700;
    border-radius: $border-radius-paper;
  }

  .card-setting-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    margin: 20px 0 25px 0;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }
  .card-setting {
    background: white;
    border-radius: $border-radius-paper;
    padding: 35px 20px;

    .card-setting-icon{
      width: 35px;
      height: 35px;
      fill: $text-color;
    }

    .inner-content {
      display: flex;
      //display: grid;
      //grid-template-columns: 50px 1fr;
      //grid-gap: 20px;
      //grid-auto-rows: auto;
      align-items: center;
      .subtitle{
        font-size: 12px !important;
      }

      .description{
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .icon {
        &.type-setting {
          height: 50px;
          width: 50px;
        }
      }

      .title {
        font-size: 16px !important;
        margin-bottom: 6px;
        font-weight: 700;
      }
    }
  }

}

.card-action-form {
  @extend .card-paper-shadow;
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-top: 20px;

  .title {
    flex: 1;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .btn-action-wrapper {
    @extend .display-flex;
    grid-gap: 15px;

    .btn {
      min-width: 150px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 25px 15px;

    .title {
      margin-bottom: 15px;
    }

    .btn-action-wrapper {
      flex-direction: column;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.card-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  .card-summary-title {
    label {
      display: block;
      font-weight: 600;
    }
  }

  .card-summary-content {
    label {
      display: block;
    }
  }
}

.table-summary {
  color: $text-color;
  table-layout: fixed;
  width: 100%;

  &.type-one{
    tr{
      &:first-child{
        td{
          padding-bottom: 4px;
          padding-top: 4px;
        }
      }
      &:nth-child(2){
        border-bottom: 1px solid $border-color;
        td{
          padding-top: 8px;
          padding-bottom: 12px;
        }
      }
      &:last-child{
        td{
          padding-top: 12px;
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }

  &.type-two{
    tr{
      td{
        padding-bottom: 8px;
        padding-top: 4px;
      }
      &:nth-last-child(2){
        td{
          padding-bottom: 16px;
        }
      }
      &:last-child{
        border-top: 1px solid $border-color;
        td{
          padding-top: 12px;
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }

  &.type-right {
    text-align: right;

    .form-control {
      text-align: right;
    }

    tr {
      td {
        &:first-child {
          width: 40%;
        }
      }
    }
  }

  tr {
    td {
      padding: 5px 15px;

      &:first-child {
        font-weight: 400;
        padding-left: 0px;
      }

      &:last-of-type {
        padding-right: 0px;
      }
    }
  }
}

.loader-height{
  height: unset !important;
}

.loader-overlay {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
}
// End Main Component
