button {
  &:focus {
    outline: none;
  }
}

.delete-icon{
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  fill: $text-color;
}

.btn {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  min-height: 28px;
  @include transition(all, 0.5s, ease);

  a {
    color: inherit;
  }

  &:hover {
    a {
    }
  }

  &:focus {
    box-shadow: none !important;
    outline: none;

    a {
    }
  }

  &:disabled,
  &.disabled {
    background: $gray-600;

    a {
    }
  }

  .loader-wrapper {
    position: relative;
    padding-left: 0px;
    @include transition(all, 0.3s, ease);

    &.loading {
      padding-left: 25px;

      .spinner-border {
        visibility: visible;
        opacity: 1;
        letter-spacing: 0px;
        left: 0px;
      }
    }

    .spinner-border {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
      visibility: hidden;
      opacity: 0;
      @include transition(all, 0.5s, ease);
    }
  }

  .icon {
    &.icon-prefix {
      margin-right: 10px;
    }
  }

  .btn-text {
    position: relative;
  }
}

.btn-light{
  background-color: transparent;
}

.react-bs-table-sizePerPage-dropdown{
  .btn-secondary{
    padding: 0.375rem 0.75rem;
  }
}

.btn-primary {
  background: $primary;
  color: $secondary-bg;
  padding-left: 2.1rem;
  padding-right: 2.1rem;
  border-radius: 16px;

  &.reverse{
    background: $secondary-bg;
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      background: $secondary-bg;
      color: $primary;

      a {
        color: $primary;
      }
    }
  }

  &.for-table{
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  &.btn-small{
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  &:hover,
  &:focus,
  &:active {
    background: $secondary;
    color: white;

    a {
      color: white;
    }
  }
}

.btn-cancel{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-secondary {
  background: #DBDBDB;
  color: $text-color;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:hover,
  &:focus,
  &:active {
  }
}

.btn-full-width {
  width: 100%;
}

.btn-small {
  font-size: 12px;
  min-height: unset;
}

.table-btn-status{
  width: fit-content;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  &.failed{
    background-color: $failed-status-bg;
    color: $failed-status-color;
  }
  &.processing{
    background-color: $processing-status-bg;
    color: $processing-status-color;
  }
  &.success{
    background-color: $success-status-bg;
    color: $success-status-color;
  }
}

.default-btn-action-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
